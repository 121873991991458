// GLOBAL : PLP & PDP

.yotpo-sr-bottom-line-text {
    color: #A4A4A4 !important;
}

// PLP ONLY
.search-results {
    .product .tile-body {
        .yotpo-sr-bottom-line-left-panel.yotpo-sr-bottom-line-score {
            display: none !important;
        }
        .yotpo-sr-bottom-line-text {
            font-family: "Mallory Light" !important;
            font-size: 14px !important;
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
        }
        .yotpo-sr-vertical-border {
            display: none !important;
        }
        .yotpo-sr-bottom-line-summary {
            @include media-breakpoint-down(md) {
                flex-direction: row !important;
                align-items: center;
            }
        }
    }
}

// PDP
.product-detail {
    .yotpo-sr-bottom-line-text {
        &::after {
            content: "Reviews";
        }
    }
}


// STORE PAGE
.page-designer-pages {
    .product-tile-pd {
        // .yotpo-sr-bottom-line-left-panel.yotpo-sr-bottom-line-score {
        //     display: none !important;
        // }
        .yotpo-sr-bottom-line-text {
            display: none;
            font-family: "Mallory Light" !important;
            font-size: 14px !important;
            @include media-breakpoint-down(md) {
                margin-top: 0;
            }
        }
        .yotpo-sr-vertical-border {
            display: none !important;
        }
        .yotpo-sr-bottom-line-summary {
            @include media-breakpoint-down(md) {
                flex-direction: row !important;
                align-items: center;
            }
        }
    }
}

// GLOBAL
.yotpo-widget-hero-section .yotpo-layout-container,
.yotpo-widget-my-rewards-widget .yotpo-tile-box,
.yotpo-widget-visual-redemption-widget .yotpo-tile-wrapper {
    height: auto !important;
}

.yotpo-vip-tiers-widget .yotpo-vip-tiers-list {
    padding-bottom: 80px !important
}

.yotpo-vip-tiers-widget .yotpo-widget-clear > div {
    background-color: #f7f7f7 !important;
}

.yotpo-widget-products-redemption .yotpo-login-button .yotpo-action-button-text {
    color: #fff;
    font-family: "Mallory Light";
    font-size: 14px !important;
    font-weight: 400;
}

.product-detail {
    .yotpo-base-layout,
    .yotpo-main-layout {
        width: 100% !important;
    }
    
    .yotpo-main-widget-tabs {
        .ugc-storefront-widgets-tabs-container__tab {
            font-size: 48px;
            letter-spacing: -2.88px;
            line-height: 55px;
            color: #A4A4A4;

            &.selected {
                color: #000;
                border-bottom: 3px solid var(--yotpo-pure-black);
            }

            @include media-breakpoint-down(md) {
                font-size: 30px;
                letter-spacing: -1.2px;
            }
        }
    }

    .yotpo-display-l,
    .yotpo-display-m {
        .yotpo-main-layout {
            .yotpo-head {
                .yotpo-headline {
                    font-size: 20px;
                    letter-spacing: -0.8px;
                    @include primaryFont(400);
                }
            }
        }
    }

    .yotpo-new-review-btn-wrapper {
        #yotpo-main-widget-btn[data-v-45e7fb23] {
            background-color: #F7F3EF;
            width: 167px;
            height: 37px;
            letter-spacing: -0.28px;
            text-transform: uppercase;
            color: #536552;
        }
    }

    .yotpo-reviews-main-widget {
        input,
        label,
        select,
        textarea {
            width: 100%;
        }
    }

    .yotpo-horizontal-pagination {
        .selected,
        .yotpo-pagination-number:hover {
            color: #fff !important
        }
    }

    .yotpo-smart-topics[data-v-28b948ae],
    .yotpo-header-headline {
        font-size: 20px;
        letter-spacing: -0.8px;
        @include primaryFont(400);
    }

    .yotpo-custom-questions-filters {
        margin-bottom: 20px;
    }

    .yotpo-smart-topic-filter[data-v-557dae96] {
        background-color: #ECEFE7;
        letter-spacing: -0.24px;
        font-size: 12px;
        padding: 0 12px;
        @include primaryFont(350);

        &:hover,
        &:active {
            border-color: #ECEFE7;
            color: #1E1E1E;
        }

        &[aria-checked="true"] {
            /* Your styles here */
            background-color: #fff;
            color: #1e1e1e;
            border-color: #536552;
        }
    }

    #yotpo-widget-btn {
        background: #F7F3EF;
        width: 167px;
        height: 37px;
        span {
            font-size: 14px;
            letter-spacing: -0.28px;
            text-transform: uppercase;
            color: #536552;
        }
    }

    .yotpo-display-m .yotpo-main-layout .yotpo-layout-header-wrapper .yotpo-bottom-line[data-v-539fe0bb] {
        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
            margin-right: 0;
            width: 100%;

            .yotpo-star-distribution {
                border: 0 !important;
            }
        }
    }
}


.yotpo-widget-referral-widget .yotpo-button-large-size,
.yotpo-widget-referral-widget .yotpo-customer-email-view {
    @include media-breakpoint-down(md) {
        max-width: 100%;
        width: 100% !important;
    }
}

.yotpo-widget-referral-widget .yotpo-center-container.yotpo-is-mobile {
    @include media-breakpoint-down(md) {
        padding: 48px 16px !important;
    }
}

.yotpo-redemption-options-container .swiper-wrapper {
    @include media-breakpoint-down(md) {
        height: auto !important;
    }
}
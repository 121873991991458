
@import "../baseTheme/settings";
@import "../components/yotpoOverrides";

.storepage {
    width: 100%;
}

#calculator{
    margin: 32px 0 0 0;

    .banner-image {
        @include media-breakpoint-down(md) {
            padding-top: 0;
        }
    }
    .banner-caption {
        @include media-breakpoint-down(md) {
            position: absolute;
        }
    }
}

.calculator-tile{
    padding: 32px;
    border-radius: 12px;
    margin-bottom: toRem(32);

    a{
        min-width: 198px;
        height: 42px;
        line-height: 42px;
        border-radius: 22px;
        text-transform: uppercase;
        color: #fff !important;
        font-family: var(--skin-body-font);
        text-align: center;
        padding: 0px 24px;
        display: inline-block;
    }

    h2, h2 p {
        align-self: stretch;
        font-family: Gotu;
        font-style: normal;
        font-size: toRem(24);
        font-weight: 400;
        line-height: 110%;
        letter-spacing: -toRem(0.96);
        margin-bottom: toRem(16);
        @include media-breakpoint-up(md) {
            font-size: toRem(36);
        }
    }
    p {
        align-self: stretch;
        font-size: toRem(14);
        font-style: normal;
        margin-bottom: toRem(16);
        font-family: var(--skin-body-font);
    }
}